<template>
	<a-space direction="vertical">
		<a-card title="战队数值配置">
			<a-table :data-source="lists" :pagination="false" :scroll="{ x: 'max-content' }">
				<a-table-column key="index" title="页面排名" data-index="index" :width="100">
					<template slot-scope="index, record">
						<a-avatar
							:size="18"
							:style="{
								backgroundColor: record.index < 7 ? '#f56a00' : '#eeeeee',
								verticalAlign: 'middle',
								fontSize: '12px'
							}"
						>
							{{ record.index }}
						</a-avatar>
					</template>
				</a-table-column>
				<a-table-column key="teamName" title="战队" data-index="teamName" :width="140">
					<template slot-scope="teamName, record">
						{{ record.teamName }}({{ record.leading }})
					</template>
				</a-table-column>
				<a-table-column key="achievement" title="能量数值" data-index="achievement" align="right" :width="140">
					<template slot-scope="achievement, record">
						{{ record.achievement | numberFormat }}
					</template>
				</a-table-column>
				<a-table-column key="achievement_value" title="展示数值" data-index="achievement_value" align="right" :width="140">
					<template slot-scope="achievement, record">
						{{ record.achievement | wNumberFormat }}万能量
					</template>
				</a-table-column>
				<a-table-column key="value" title="增减数值" data-index="value">
					<template slot-scope="value, record">
						<a-input v-model="record.value" style="width:200px" placeholder="请输入增加或减少数值" />
					</template>
				</a-table-column>
				<a-table-column key="action" title="操作" data-index="action">
					<template slot-scope="action, record">
						<a-popconfirm :title="`确定要${/^-/.test(record.value) ? '减少' : '增加'}?`" @confirm="onSave(record)">
							<a-button type="danger" v-if="/^-/.test(record.value)">减少</a-button>
							<a-button type="primary" v-else>增加</a-button>
						</a-popconfirm>
					</template>
				</a-table-column>
			</a-table>

			<!-- <a-row type="flex" :gutter="10" v-for="item in lists" :key="item.name">
					<a-col flex="180px">
						<a-space>
							<a-avatar
								:size="18"
								:style="{
									backgroundColor: item.index < 7 ? '#f56a00' : '#eeeeee',
									verticalAlign: 'middle',
									fontSize: '12px'
								}"
							>
								{{ item.index }}
							</a-avatar>
							<div>{{ item.teamName }}({{ item.leading }})</div>
						</a-space>
					</a-col>
					<a-col flex="100px" style="text-align: right;">{{ item.achievement | numberFormat }}</a-col>
					<a-col flex="140px" style="text-align: right;">{{ item.achievement | wNumberFormat }} 万能量</a-col>
					<a-col :span="1"></a-col>
					<a-col flex="200px"><a-input v-model="item.value" /></a-col>
					<a-col :span="4">
						<a-popconfirm title="确定要增加?" @confirm="onSave(item)"><a-button type="primary">增加</a-button></a-popconfirm>
					</a-col>
				</a-row>
			</a-space> -->
		</a-card>
	</a-space>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			lists: []
		};
	},
	filters: {
		wNumberFormat(value) {
			value = (Math.round(value * 100) / 1000000).toFixed(2);
			return String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		},
		numberFormat(value) {
			return String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
		}
	},
	async mounted() {
		this.getConfig();
	},
	methods: {
		async getConfig() {
			const response = await this.$api.get(`/activity_twelve_list?type=2`);
			if (response && response.code == 200) {
				const achievements = (response.data || []).map(v => v.achievement).sort((a, b) => b - a);

				response.data = (response.data || []).map(item => {
					item.value = undefined;
					item.index = achievements.indexOf(item.achievement) + 1;
					return item;
				});
				this.lists = response.data;
			}
		},
		async onSave(item) {
			const value = item.value; // .replace(/[^\d\.]/g, '');
			if (!/^-?\d+$/.test(value)) {
				return this.$message.error('请输入正确的数值');
			}
			const response = await this.$api.post('/activity_twelve_insert', {
				id: item.id,
				num: +value
			});
			if (response && response.code == 200) {
				this.$message.success('保存成功');
				this.getConfig();
			} else {
				this.$message.error(response.msg);
			}
		}
	}
};
</script>
